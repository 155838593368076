
:root {
	--bgcolor:#00000000;
}

* {
	margin: 0;
	padding: 0;
}
.navbar{
  position: fixed;
  z-index: 50;

  
}
.body {
	min-height: 100vh;
	display: grid;
	place-items: center;
	background: var(--bgcolor);
	color: #ffffff;
    /* font-family: unlock; */
font-weight: bolder;
z-index: 50;
}
.nav-ul {
	list-style: none;
    color: #ffffff;
    font-weight: bolder;
}
.nav-title {
	font-size: 3rem;
	position: relative;
	text-transform: uppercase;
	transition: all 300ms ease;
	width: fit-content;
	cursor: pointer;
}
.nav-title:hover {
	transform: skew(10deg);
}
.nav-title::before {
	content: attr(data-name);
	position: absolute;
	top: 0;
	left: -20px;
	background: var(--bgcolor);
	height: 2.5rem;
	overflow: hidden;
	transition: all 300ms ease;
	padding-left: 20px;
}
.nav-title:hover::before {
	top: -0px;
	left: -20px;
	color: #ea9901;
}

.nav-title::after {
	content: "";
	height: 4px;
	width: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ea9901;
	transition: all 300ms ease;
}
.nav-title:hover::after {
	width: 120%;
	/* outline: 2px solid #ea9901; */
}

.cross-icon, .hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
}

.line {
  width: 50px;
  height: 3px;
  background-color: rgb(255, 255, 255);
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: absolute;
}

.line1 {
  transform: translateY(-6px);
}

.line2 {
  transform: translateY(6px);
}

/* When isOpen is true */
.cross-icon .line1 {
  transform: rotate(45deg);
}

.cross-icon .line2 {
  transform: rotate(-45deg);
}

/* When isOpen is false */
.hamburger-icon .bar {
  background-color: rgb(255, 255, 255);
  transition: transform 0.3s ease, opacity 0.3s ease;
  height: 3px;
  width: 50px;
}

.hamburger-icon .bar:first-child {
  transform: translateY(-6px);
}

.hamburger-icon .bar:last-child {
  transform: translateY(6px);
}







/* Basic styling for the navbar */



/* Fullscreen Menu Styling */
.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000b8;  /* Dark background */
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.fullscreen-menu.open {
  transform: translateX(0);  /* Show menu */
}

.fullscreen-menu ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.fullscreen-menu li {
  margin: 20px 0;
}

.fullscreen-menu a {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.fullscreen-menu a:hover {
  color: #FB0C0C;  /* Hover effect for links */
}






.btn {
  --btn-color: rgb(124 45 18);
  --btn-bg: rgb(252 211 77);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: var(--btn-bg);
  color: var(--btn-color);
  padding:5px 10px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.3rem;
  /* font-family: system-ui; */
  border: 2px solid var(--btn-color);
  transition: 100ms ease;
  box-shadow: 5px 5px 0 0 var(--btn-color);
}

.btn--secondary {
  --btn-color: #444;
  --btn-bg: #fafafa;
}

.btn svg {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.btn:hover {
  box-shadow: 2px 2px 0 0 var(--btn-color);
}

.btn:active {
  transition: 50ms ease;
  box-shadow:  0 0 0 0 var(--btn-color);
}

.btn:focus-visible {
  outline: 0;
  --btn-color: #002cc8;
}

.btn:focus-visible::after {
  position: absolute;
  left: 50%;
  top: calc(100% + 12px);
  transform: translateX(-50%);
  content: "\21E7";
  animation: float .5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateX(-50%) translatey(0px);
  }
  50% {
    transform: translateX(-50%) translatey(-6px);
  }
  100% {
    transform: translateX(-50%) translatey(0px);
  }
}



/* body {
  display: grid;
  place-content: center;
} */

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.navbar{
  
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 12vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:transparent;
    z-index: 20;
    /* padding: 0 5vw; */
  
  
a{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 10px 10px;
  color: #f01616;
  text-transform:uppercase;
  text-decoration:none;
  letter-spacing: 5px;
  font-size:30px;
  box-shadow: 0 20px 50px rgba(0,0,0,.5);
  overflow:hidden;
}

a::before
{
  content:"";
  position:absolute;
  top:2px;
  left:2px;
  bottom:2px;
  width:50%;
  background:rgba(255,255,255,0.05);
  
}
a span:nth-child(1)
{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:2px;
  background:linear-gradient(to right, #0c002b ,#ff1717);
  animation: animate1 2s linear infinite;
  animation-delay:1s;
  
}
a span:nth-child(3)
{
  position:absolute;
  bottom:0;
  left:0;
  width:100%;
  height:2px;
  background:linear-gradient(to left, #0c002b ,#ff1717);
   animation: animate3 2s linear infinite;
  animation-delay:1s;
  
}
a span:nth-child(4)
{
  position:absolute;
  top:0;
  left:0;
  width:2px;
  height:100%;
  background:linear-gradient(to top, #0c002b ,#ff1717);
   animation: animate4 2s linear infinite;
  animation-delay:2s;
  
}





.animation {
    min-height: 130vh;  
  }
  

  
}

  @media (max-width: 500px) {


.navbar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 5px; */
  background:transparent;
  z-index: 20;


    :root {
      --bgcolor:#00000000;
    }
    
    * {
      margin: 0;
      padding: 0;
    }
 
    .body {
      min-height: 100vh;
      display: grid;
      place-items: center;
      background: var(--bgcolor);
      color: #ffffff;
        /* font-family: unlock; */
    font-weight: bolder;
    z-index: 20;
    }
    .nav-ul {
      list-style: none;
        color: #ffffff;
        font-weight: bolder;
    }
    .nav-title {
      font-size: 1.5rem;
      position: relative;
      text-transform: uppercase;
      transition: all 300ms ease;
      width: fit-content;
      cursor: pointer;
      letter-spacing: 2px;
      /* margin: 0; */
    }
    .nav-title:hover {
      transform: skew(10deg);
    }
    .nav-title::before {
      content: attr(data-name);
      position: absolute;
      top: 0;
      left: -20px;
      background: var(--bgcolor);
      height: 1.1rem;
      overflow: hidden;
      transition: all 300ms ease;
      padding-left: 20px;
    }
    .nav-title:hover::before {
      top: -0px;
      left: -20px;
      color: #ea9901;
    }
    
    .nav-title::after {
      content: "";
      height: 4px;
      width: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #ea9901;
      transition: all 300ms ease;
    }
    .nav-title:hover::after {
      width: 120%;
      /* outline: 2px solid #ea9901; */
    }
    
    
    
    
    
    
    
    
    
    
    /* Basic styling for the navbar */
    
    .hamburger-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50px;
      height: 11px;
      cursor: pointer;
      /* background-color: rgb(0, 0, 0);
      padding: 10px; */
    }
    
    .bar {
      height: 3px;
      width: 100%;
      background-color: #fffffe;
      border-radius: 2px;
    }
    
    /* Fullscreen Menu Styling */
    .fullscreen-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: #000000e8;  /* Dark background */
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;
    }
    
    .fullscreen-menu.open {
      transform: translateX(0);  /* Show menu */
    }
    
    .fullscreen-menu ul {
      list-style: none;
      padding: 0;
      text-align: center;
    }
    
    .fullscreen-menu li {
      margin: 10px 0;
    }
    
    .fullscreen-menu a {
      color: white;
      text-decoration: none;
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    .fullscreen-menu a:hover {
      color: #FB0C0C;  /* Hover effect for links */
    }
    
    
    
    
    
    .btn {
      --btn-color: rgb(124 45 18);
      --btn-bg: rgb(252 211 77);
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      background-color: var(--btn-bg);
      color: var(--btn-color);
      padding:5px 10px;
      border-radius: 8px;
      border: 0;
      cursor: pointer;
      font-weight: 600;
      font-size: 1rem;
      /* font-family: system-ui; */
      border: 2px solid var(--btn-color);
      transition: 100ms ease;
      box-shadow: 5px 5px 0 0 var(--btn-color);
    }
    
    .btn--secondary {
      --btn-color: #444;
      --btn-bg: #fafafa;
    }
    
    .btn svg {
      width: 50px;
      height: 50px;
      flex-shrink: 0;
    }
    
    .btn:hover {
      box-shadow: 2px 2px 0 0 var(--btn-color);
    }
    
    .btn:active {
      transition: 50ms ease;
      box-shadow:  0 0 0 0 var(--btn-color);
    }
    
    .btn:focus-visible {
      outline: 0;
      --btn-color: #002cc8;
    }
    
    .btn:focus-visible::after {
      position: absolute;
      left: 50%;
      top: calc(100% + 12px);
      transform: translateX(-50%);
      content: "\21E7";
      animation: float .5s ease-in-out infinite;
    }
    
    @keyframes float {
      0% {
        transform: translateX(-50%) translatey(0px);
      }
      50% {
        transform: translateX(-50%) translatey(-6px);
      }
      100% {
        transform: translateX(-50%) translatey(0px);
      }
    }
    
    
    
    body {
      display: grid;
      place-content: center;
    }
    
    .container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    
    a{
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      padding: 10px 10px;
      color: #f01616;
      text-transform:uppercase;
      text-decoration:none;
      letter-spacing: 5px;
      font-size:30px;
      box-shadow: 0 20px 50px rgba(0,0,0,.5);
      overflow:hidden;
    }
    
    a::before
    {
      content:"";
      position:absolute;
      top:2px;
      left:2px;
      bottom:2px;
      width:50%;
      background:rgba(255,255,255,0.05);
      
    }
    a span:nth-child(1)
    {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:2px;
      background:linear-gradient(to right, #0c002b ,#ff1717);
      animation: animate1 2s linear infinite;
      animation-delay:1s;
      
    }
    a span:nth-child(3)
    {
      position:absolute;
      bottom:0;
      left:0;
      width:100%;
      height:2px;
      background:linear-gradient(to left, #0c002b ,#ff1717);
       animation: animate3 2s linear infinite;
      animation-delay:1s;
      
    }
    a span:nth-child(4)
    {
      position:absolute;
      top:0;
      left:0;
      width:2px;
      height:100%;
      background:linear-gradient(to top, #0c002b ,#ff1717);
       animation: animate4 2s linear infinite;
      animation-delay:2s;
      
    }
    
    
    
    
    
    .animation {
        min-height: 130vh;  
      }

}

  }












