
.marquee-container {
    width: 100%; /* Full-width container */
    overflow: hidden; /* Hide overflowing text */
  /* Prevent text from wrapping */
    background-color: #000000; /* Optional background color */
    color: #ff00007b; /* Text color */
    font-size: 200px;
    line-height: 200px;
    /* padding-bottom: 10vh; */
/* height: 30vh; */
/* height: fit-content; */
padding: 0;

  }
  
  .marquee {
    display: flex;
    animation: scroll 10s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%); /* Start off-screen to the right */
    }
    100% {
      transform: translateX(-100%); /* Move off-screen to the left */
    }
  }
  

  .sss{
    font-size: 4.5rem;
    line-height: 50px;
  }



  @media (max-width: 500px) {


.sss{
    display: none;
}
.marquee{
  display: none;
}

  }