@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&family=Unlock&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

.sponser-layout {
    background-color: #c1204b;
    padding: 0 7%;
}

.spon-intro-text h2 {
    font-family: Unlock;
    font-size: 3.5em;
    color: #FFFB00;
    text-align: center;
    padding-top: 4%;
}

.spon-intro-text p {
    font-size: 1.1em;
    font-weight: bold;
    color: white;
    line-height: 1.5em;
    text-align: center;
    padding: 2%;
}
.sponsers-content::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}
.sponser {
    /* max-width: 15vw; */
    /* max-height: 20vh; */
    text-align: center;
    color: white;
    padding:3% ;
    border-radius: 10px;
    line-height: 2em;
    /* background-color: hsla(0, 0%, 0%,0.2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;
}
.spons-name a{
    font-weight: bold;
    color: #FFFB00;
    font-size: 1.3em;
}
.spons-type{
    font-size: 1.3em;
}
.sponser img{
    max-height: 230px;
    /* margin-bottom: 5%; */
    /* height: 200px; */
}
.sponsers-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0;
}
.spon-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10vw;
    grid-row-gap:10px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.Worldfest-section{
    grid-template-columns: 1fr 1fr;
}
/* .spon-section div {
    margin-bottom: 2%;
} */
.spon-instagramlink{
    color:white;
    font-weight: 600;
    font-size: 1.2em;
}
.spon-workshoplink{
    color: white;
    font-weight: 600;
    font-size: 1.2em;
}
.sponsers-content h2{
    color: #FFFB00;
    font-size: 2.5em;
    font-weight: bold;
    font-family: Unlock;
    line-height: 3em;
    text-align: center;
}
/* .sponser-img{ */
    /* min-width: 300px; */
    /* margin: 0 auto; */
/* } */
@media screen and (max-width:768px){
    .spon-intro-text h2{
        text-align: center;
        font-size: 2em;
        padding-top: 20%;
    }
    .sponser-layout {
        padding: 0 2%;
    }
    .spon-intro-text p{
        padding:5%;
        text-align: center;
        font-size: 1em;

    }
    .sponsers-content {
        margin: 0;
    }
    .spon-intro-text{
        margin: 0;
    }
    .sponsers-content h2{
        font-size: 1.5em;
    }
    .spons-name{
        font-size: 1em;
    }
    .spons-type{
        font-size: 1em;
    }
    .sponser-img {
        max-width: 300px;
    }
    .spon-section {
        /* display: grid; */
        grid-template-columns: 1fr;
        grid-column-gap: 15vw;
        /* grid-row-gap:10px;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center; */
    }
    
    
}
/* Film-themed hover transition */
@keyframes filmTransition {
    0% {
        opacity: 1;
        transform: scale(1);
        filter: brightness(1);
    }

    50% {
        opacity: 0.7;
        transform: scale(1.05);
        filter: brightness(1.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
        filter: brightness(1);
    }
}

/* .sponser:hover {
    animation: filmTransition 1s ease-in-out;
    border: 3px dotted #fffb00;
    /* Optional: Film reel style border */
    /* box-shadow: 0 0 20px rgba(255, 255, 0, 0.8); */
    /* Optional: Glow effect */

/* } */