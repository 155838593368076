.Proshw{


  .body{
    position: relative;
  }
  
  .btn {
    --btn-color: rgb(124 45 18);
    --btn-bg: #ea9901;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: #000000;
    background-color: var(--btn-bg);
    color: var(--btn-color);
    padding: 10px 35px;
    border-radius: 8px;
    border: 0;
    cursor: pointer;
    font-weight: 600;
    font-size: 2rem;
    /* font-family: system-ui; */
    border: 2px solid var(--btn-color);
    transition: 100ms ease;
    box-shadow: 5px 5px 0 0 var(--btn-color);
  }
  
  .btn--secondary {
    --btn-color: #000000;
    --btn-bg: #fafafa;
  }
  
  .btn svg {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }
  
  .btn:hover {
    box-shadow: 2px 2px 0 0 var(--btn-color);
  }
  
  .btn:active {
    transition: 50ms ease;
    box-shadow:  0 0 0 0 var(--btn-color);
  }
  
  .btn:focus-visible {
    outline: 0;
    --btn-color: #002cc8;
  }
  
  .btn:focus-visible::after {
    position: absolute;
    left: 50%;
    top: calc(100% + 12px);
    transform: translateX(-50%);
    content: "\21E7";
    animation: float .5s ease-in-out infinite;
  }
  
  @keyframes float {
    0% {
      transform: translateX(-50%) translatey(0px);
    }
    50% {
      transform: translateX(-50%) translatey(-6px);
    }
    100% {
      transform: translateX(-50%) translatey(0px);
    }
  }
  
  /* html, body {
    height: 100%;
  } */
  
  body {
    display: grid;
    place-content: center;
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  a{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding: 15px 30px;
    color: #f01616;
    text-transform:uppercase;
    text-decoration:none;
    letter-spacing: 5px;
    font-size:30px;
    box-shadow: 0 20px 50px rgba(234, 8, 8, 0.5);
    overflow:hidden;
  }
  
  a::before
  {
    content:"";
    position:absolute;
    top:2px;
    left:2px;
    bottom:2px;
    width:50%;
    background:rgba(255,255,255,0.05);
    
  }
  a span:nth-child(1)
  {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:2px;
    background:linear-gradient(to right, #0c002b ,#ff1717);
    animation: animate1 2s linear infinite;
    animation-delay:1s;
    
  }
  a span:nth-child(3)
  {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:2px;
    background:linear-gradient(to left, #0c002b ,#ff1717);
     animation: animate3 2s linear infinite;
    animation-delay:1s;
    
  }
  a span:nth-child(4)
  {
    position:absolute;
    top:0;
    left:0;
    width:2px;
    height:100%;
    background:linear-gradient(to top, #0c002b ,#ff1717);
     animation: animate4 2s linear infinite;
    animation-delay:2s;
    
  }
  
  
  
  
  
  .animation {
      min-height: 130vh;  
    }
    
  
    
  
    
    .parallax-box {
      width: 80vw;
      height:80vw;
      /* background-color: lightblue; */
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 10vw;
      font-weight: bold;
      font-family: 'YourFontName', serif;
      z-index: 2; /* Places text above the video */
        text-shadow: 0px 12px 0 #D00000;
        line-height: 200px;
        margin-left: 0;
    }
  
  @keyframes animate1
  {
    0%
    {
      transform:translateX(-100%);
  }
    100%
    {
      transform:translateX(100%);
    }
  }
  a span:nth-child(2)
  {
    position:absolute;
    top:0;
    right:0;
    width:2px;
    height:100%;
    background:linear-gradient(to bottom, #0c002b ,#ff1717);
    animation: animate2 2s linear infinite;
    animation-delay:2s;
  }
  @keyframes animate2
  {
    0%
    {
      transform:translateY(-100%);
  }
    100%
    {
      transform:translateY(100%);
    }
  }
  @keyframes animate4
  {
    0%
    {
      transform:translateY(100%);
  }
    100%
    {
      transform:translateY(-100%);
    }
  }
  
  @keyframes animate3
  {
    0%
    {
      transform:translateX(100%);
  }
    100%
    {
      transform:translateX(-100%);
    }
  }
  
  
  
  
  @media (max-width: 500px) {
    
    .wrapper {
      display: grid;
      place-content: center;
      background-color: var(--background-color);
      min-height: 100vh;
      font-family: "Oswald", sans-serif;
      font-size: clamp(1.5rem, 1rem + 7vw, 5rem);
      font-weight: 700;
      text-transform: uppercase;
      color: var(--text-color);
    }
      
    .parallax-box {
      width:80vw;
      height:80vw;
      /* background-color: lightblue; */
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size:2.5rem;
      font-weight: bold;
      font-family: 'YourFontName', serif;
      z-index: 2; /* Places text above the video */
        text-shadow: 0px 4px 0 #D00000;
        line-height: 10vh;
  
    }
  
    .btn {
      --btn-color: rgb(124 45 18);
      --btn-bg: #ea9901;
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      background-color: var(--btn-bg);
      color: var(--btn-color);
      padding: 5px 5px;
      border-radius: 8px;
      border: 0;
      cursor: pointer;
      font-weight: 600;
      font-size: 1.3rem;
      font-family: system-ui;
      border: 2px solid var(--btn-color);
      transition: 100ms ease;
      box-shadow: 5px 5px 0 0 var(--btn-color);
      margin-bottom: 5vh;
    }
   
    }
  }
  
  
  * {
    box-sizing: border-box;
  }
  :root {
    --background-color: black;
    --text-color: hsl(0, 0%, 100%);
  }
  body {
    margin: 0;
  }
  .wrapper {
    display: grid;
    place-content: center;
    background-color: var(--background-color);
    min-height: 100vh;
    font-family: "Oswald", sans-serif;
    font-size: clamp(1.5rem, 1rem + 14vw, 11rem);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--text-color);
  }
  
  .wrapper > div {
    grid-area: 1/1/-1/-1;
  }
  .top {
    clip-path: polygon(0% 0%, 100% 0%, 100% 48%, 0% 58%);
  }
  .bottom {
    clip-path: polygon(0% 60%, 100% 50%, 100% 100%, 0% 100%);
    color: transparent;
    background: -webkit-linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background: linear-gradient(177deg, black 53%, var(--text-color) 65%);
    background-clip: text;
    -webkit-background-clip: text;
    transform: translateX(-0.02em);
  }