

.whole{
 font-family: 'YourFontName', serif; 
 min-height:100vh;
}


.image-wrapper {
    position: relative;
    // width: 100%;
    // height: 100%;
    &:hover .overlay {
        opacity: 0; 
      }
    img {
      display: block;

      object-fit: cover;
    }
  
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.65); 
      opacity: 1;
      transition: opacity 0.3s linear;
    }
  
    &:hover .overlay {
      opacity: 0;
    }
  }
  



  .headline {
    z-index: 20; 
    pointer-events: none; 
  }
  
