/* General styles */
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html {
    scroll-behavior: smooth;
}

/* Layout for TOC and Content */


/* TOC Button */
.toc-button {
    position: fixed;
    right: 0;
    top: 20%;
    z-index: 1;
    background-color: #b51e1e;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0.9;
}

.toc-button:hover {
    background-color: #d13939;
}

/* TOC Visibility */
.faq-toc {
    position: fixed;
    left: 0;
    top: 20vw;
    height: auto;
    width: 100%;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transform: translateX(+100%);
    transition: transform 0.3s ease;
    text-align: left;
}

.faq-toc.tocvisible {
    transform: translateX(0);
}
.popcorn-img {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.7;
    /* Keeps the image behind text */
}

.popcorn-img img {
    width: 80%;
}
.faq-layout {
    background-color: #c1204a;
    /* Keeps the background color */
    position: relative;
    /* Allows other content to be layered above it */
    z-index: 0;
    /* Ensures layout stays in front of the image */
}
.faq-section {
    z-index: 1;
    /* Keeps text above the image */
}


.faq-toc ul {
    list-style-type: none;
    padding: 0;
    margin-left: 5%;
}
.faq-toc h1 {
    color: #b51e1e;
    text-decoration: none;
    /* font-family: Bowlby One SC; */
    font-size: 45px;
    font-weight: 400;
    line-height: 90px;
    text-align: left;
    margin-left: 5%;
}
.faq-toc ul li a:hover {
    text-decoration: none;
}
/* Content Sections (Right side) */
.faq-toc ul li{
    margin-bottom: 6%;
}

.faq-section h1 {
    color: #FFFB00;
    /* font-family: Bowlby One SC; */
    /* font-size: 2rem; */
    font-weight: 400;
    line-height: 62.53px;
    text-align: center;
    margin: 5em 0em 1.5em 0em;
    margin-top: 2rem;
}

.urbanist {
    /* font-family: "Urbanist", sans-serif; */
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}
ol {
    list-style-type: decimal;
    /* Ensures numbering appears */
    margin-left: 20px;
    /* Adds proper indentation */
}
.faq-section ol li {
    /* font-family: Urbanist; */
    font-size: 22px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    text-decoration-skip-ink: none;
}

.faq-section ol li p, .faq-section ol li ul li, .faq-section ol li ul li {
    font-family: Urbanist;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.64px;
    text-align: left;
    text-decoration-skip-ink: none;
}


.faq-toc ul li a {
    text-decoration: none;
    color: black;
    font-family: Bowlby One SC;
}
.faq-content {
    flex-basis: 100%;
    padding: 10%;

}
.faq-section ol, .faq-section li {
    color: #FFFB00BA;
}

/* Hidden TOC for Desktop */
@media (min-width: 768px) {
    .toc-button {
        display: none;
    }
    .faq-layout {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }


    .faq-toc {
        flex-basis: 40%;
        position: sticky;
        top: 5vw;
        transform: none;
        transition: none;
        width: auto;
        box-shadow: none;
        margin-left: 5%;
    }
    .faq-toc ul li a.active {
        font-size: 40px;
        /* Larger size to highlight */
    }

    .faq-toc ul li a {
        color: #FFFB00;
        font-size: 25px;
        line-height: 52.44px;
    }

    .faq-toc h1 {
        color: #FFFB00;
        font-size: 60px;
        line-height: 156.28px;
    }
    .faq-section ol li {
        font-size: 35px;
    }

    .faq-section ol li p, .faq-section ol li ul li {
        font-size: 22px;
        line-height: 32px;
    }
    .faq-section h1{
        font-size: 3rem;
    }
    
}
@media screen and (max-width: 768px) {
    .faq-toc{
        background-color: #FFFB00;
        opacity: 0.95;
        border-top-left-radius: 5%;
        border-bottom-left-radius: 5%;
    }
    .popcorn-img{
        display: none;
    }
    .faq-section h1{
        font-size: 2rem;
    }
    
}