@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&family=Unlock&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
.rules-page{
    background-color: #c1204b;
}
.oat-heading{
    color: #FFFB00;
    font-family: Unlock;
    font-size: 3em;
    text-align: center;
    padding-top:10vh ;
}
.oat-qn li {
    margin-left: 20px;
    list-style-type: circle;
}
.oat-ans li{
    margin-left: 20px;
    list-style-type: disc;
}
.oat-content{
    font-size: 1.2em;
    /* margin: 5% 10%; */
    padding: 5vh 10vw;
    text-align: left;
    color: white;
    line-height: 1.7em;
}
.oat-img1{
    position: fixed;
    z-index: -1;
    opacity: 0.5;
    transform: rotate(90deg);
    top:0;
    left: -10%;
}
.oat-img2{
    position: fixed;
    z-index: -1;
    opacity: 0.5;
    transform: rotate(270deg);
    right: 0;
    bottom: 0;
}
.popcorn-img-oat{
    width: 80%;
}
@media screen and (max-width:768px){
    .oat-heading{
        font-size: 1.5em;
        padding-top: 12vh;
    }
    .oat-content{
        font-size: 1.2em;
        margin-left: 15%;
    }
}