@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One+SC&family=Unlock&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.contact-background {
    background-image: url(../assets/Rectangle.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* Keep the image fixed to the viewport */
    position: fixed;
    /* Use absolute positioning */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -2;
    /* Layer it beneath other content */
}

.contact-content{
    position: relative;
}
/* html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow-x: hidden;
    scroll-behavior: smooth;
} */

/* Layout for TOC and Content */
.contact-layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

/* Table of Contents (Left side) */
.contact-toc {
    flex-basis: 40%;
    text-align: left;
    padding-right: 2.5%;
    margin-left: 2.5%;
    position: sticky;
    top: 16vw;
}

.c-bgi {
    width: 450px
}

.contacts-img1 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    /* Keeps the image behind text */
}

.contacts-img2 {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1;
    /* Keeps the image behind text */
}

.contacts-img3 {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    /* Keeps the image behind text */
}

.contacts-img4 {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;
    /* Keeps the image behind text */
}
.contacts-img5{
    position: fixed;
    bottom: 0;
    left:37%;
}
.contacts {
    width: 25%;
    margin: 3.5%;
}


.contact-heading {
    font-family: Unlock;
    font-size: 127.71px;
    font-weight: 400;
    line-height: 136.39px;
    text-align: center;
    color: #FFFB00BA;
    margin-bottom: 20px;
}




.contact-section {
    position: relative;

    /* Keeps text above the image */
}

/* Active TOC link */
.contact-toc ul li a.active {
    font-size: 45px;
    /* Larger size to highlight */
}

.contact-toc ul {
    list-style-type: none;
    padding: 0;
}

.contact-toc ul li a {
    color: #FFFB00BA;
    font-family: Unlock;
    font-size: 26.37px;
    font-weight: 400;
    line-height: 33.5px;
    text-align: left;
    text-decoration: none;
}



.contact-toc ul li a:hover {
    text-decoration: none;
}

/* Content Sections (Right side) */
.contact-content {
    flex-basis: 60%;
    margin-left: 10%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}

.contact-section h1 {
    color: #FFFFFF;
    font-family: Unlock;
    font-size: 35px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    border-radius: 25px;
    margin: 1em 0 0.5em 0;
}


.contact-img-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.contact-img-section div {
    background-image: url(../assets/contact-bg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    margin: 2.5%;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25%;
    margin-bottom: 0;
    width: 200px;
}

.contact-card {
    text-align: center;
}

.contact-card img.contact-image {
    width: 150px;
    margin-bottom: 2%;
    border-radius: 15px;
}

.contact-card .contact-role {
    font-weight: bold;
    margin-bottom: 1%;
    color: #774E18;
    font-size: 1em;
    margin-top: 15%;
    max-height: 15%;
}

.contact-card .contact-name {
    font-size: 1em;
    margin-bottom: 0.5%;
    color: #774E18;
    line-height: 90%;
    max-height: 20%;
    max-width: 80%;
}
.contact-card .contact-details{
    line-height: 95%;
}
.contact-card .contact-details a {
    color: #774E18;
    text-decoration: none;
}
.contact-details{
    display: flex;
    padding-bottom: 0%;
}
.contact-logo {
    font-size: 1.4rem;
    margin-top: 30%;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}
@media (min-width:768px) and (max-width:1260px){
    .contact-img-section div{
        padding-bottom: 50%;
    }
    
}

@media screen and (max-width: 768px) {
    .contact-layout {
        flex-direction: column;
        align-items: center;
    }

    .contact-content {
        flex-basis: 100%;
        padding: 0;
        margin: 0;
    }

    .contact-section h1 {
        margin-top: 2rem;
        font-size: 2rem;
        text-align: center;
    }


    .contact-heading {

        font-size: 3rem;

    }


    .contact-img-section {
        flex-direction: column;
        align-items: center;
    }

    .contact-img-section div {
        width: 75%;
        padding-bottom: 65%;
    }
    .contact-toc{
        margin-left: 0;
        position: fixed;
        left: 0;
        top: 20vw;
        height: auto;
        width: 100%;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
        transform: translateX(+100%);
        transition: transform 0.3s ease;
        background-color: #FFFB00BA;
        padding: 5%;
        border-top-left-radius: 5%;
        border-bottom-left-radius: 5%;
    }
    .contact-toc.tocvisible{
        transform: translateX(0);
    }
    .contact-toc ul li a{
        font-size: 20px;
        color: black;
    }
    .contact-toc ul li a.active{
        font-size: 20px;
    }
   
    
}
@media (min-width:410px) and (max-width: 768px){
    .contact-card img.contact-image {
            width: 250px;
        }
        .contact-card .contact-role {
            font-size: 1.5rem;
        }

        .contact-card .contact-name {
            font-size: 1.5rem;
        }

        .contact-logo {
            font-size: 2rem;
        }
}
@media (min-width:320px) and (max-width:410px){
    .contact-card img.contact-image {
            width: 200px;
        }
    .contact-card .contact-role {
        font-size: 1.5rem;
    }

    .contact-card .contact-name {
        font-size: 1.5rem;
    }

    .contact-logo {
        font-size: 2.5rem;
    }
}
@media (min-width:260px) and (max-width:320px){
.contact-card img.contact-image {
        width: 160px;
    }
    .contact-card .contact-role {
        font-size: 1.2rem;
    }

    .contact-card .contact-name {
        font-size: 1.2rem;
    }

    .contact-logo {
        font-size: 1.5rem;
    }
}